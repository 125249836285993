import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import { useNavigate } from 'react-router-dom';
import SurveyModal from '../shared/SurveyModal';
import { Button, useDisclosure } from '@chakra-ui/react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pendingSurvey, setPendingSurvey] = useState(null); // To store the pending survey ID
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get('/auth/verify-token');
        setUser(response.data.user);
      } catch (error) {
        setUser(null);
        localStorage.removeItem('userToken');
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    fetchUser();

    const interval = setInterval(async () => {
      try {
        await axiosInstance.get('/auth/check-token');
      } catch (error) {
        setUser(null);
        localStorage.removeItem('userToken');
        navigate('/login');
        clearInterval(interval);
      }
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [navigate]);

  useEffect(() => {
    if (user) {
      const checkPendingSurvey = async () => {
        try {
          const response = await axiosInstance.get('/surveys/check-pending-survey');
          console.log(response.data)
          if (response.data.hasPendingSurvey) {
            // Check if survey is pending and hasn't been completed
            setPendingSurvey(response.data.surveyId);  // Store the survey ID
            onOpen();  // Open the survey modal if a pending survey exists
          }
        } catch (error) {
          console.error('Error checking pending survey', error);
        }
      };

      checkPendingSurvey();
    }
  }, [user, onOpen]);

  const logout = async () => {
    try {
      localStorage.removeItem('userToken');
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser, loading, logout }}>
      {/* Pass the surveyId if any pending survey exists */}
      <SurveyModal isOpen={isOpen} onClose={onClose} surveyId={pendingSurvey} />
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
