import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Stack,
  Textarea,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import axiosInstance from '../services/axiosInstance';

const SurveyModal = ({ isOpen, onClose, surveyId }) => {
  const [survey, setSurvey] = useState(null);
  const [responses, setResponses] = useState([]); // Updated to store responses as an array of objects
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (isOpen) {
      // Fetch the survey based on the surveyId
      console.log(surveyId)
      axiosInstance
        .get(`/surveys/get/${surveyId}`)
        .then((response) => {
          setSurvey(response.data);
        })
        .catch((error) => {
         
        });
    }
  }, [isOpen, surveyId]);

  const handleResponseChange = (questionId, value) => {
    setResponses((prev) => {
      const existingResponse = prev.find((response) => response.questionId === questionId);
      if (existingResponse) {
        // Update the response if it already exists
        return prev.map((response) =>
          response.questionId === questionId ? { ...response, answer: value } : response
        );
      } else {
        // Add new response
        return [...prev, { questionId, answer: value }];
      }
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Get the current user (this can come from context or state)
      const userId = 'YOUR_USER_ID'; // Replace with actual user ID logic

      // Submit the responses to the backend, matching the schema format
      await axiosInstance.post('/reply/submit', {
        userId,
        surveyId: survey._id,
        responses,
      });

      toast({
        title: 'Survey submitted',
        description: 'Your responses have been submitted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      toast({
        title: 'Error submitting survey',
        description: 'There was an issue submitting your responses.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  if (!survey) return null;

  // Check if the survey language is Arabic
  const isArabic = survey.title && /[\u0600-\u06FF]/.test(survey.title);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent dir={isArabic ? 'rtl' : 'ltr'}>
        <ModalHeader>{survey.title}</ModalHeader>
        <ModalBody>
          {survey.questions.map((question) => (
            <FormControl key={question._id} mb={4}>
              <FormLabel>{question.questionText}</FormLabel> 
              {question.type === 'multiple-choice' ? (
                <RadioGroup
                  onChange={(value) => handleResponseChange(question._id, value)}
                  value={responses.find((response) => response.questionId === question._id)?.answer || ''}
                >
                  <Stack spacing={4}>
                    {question.options.map((option, index) => (
                      <Radio key={index} value={option}>
                        {option}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              ) : (
                <Textarea
                  value={responses.find((response) => response.questionId === question._id)?.answer || ''}
                  onChange={(e) => handleResponseChange(question._id, e.target.value)}
                  placeholder="Your response"
                />
              )}
            </FormControl>
          ))}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            colorScheme="green"
            onClick={handleSubmit}
            isLoading={loading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SurveyModal;
