import React, { useState, useEffect } from "react";
import axiosInstance from "../services/axiosInstance";
import SurveyStatisticsPopup from "./SurveyStatisticsPopup ";


const SurveyTable = () => {
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSurveyId, setSelectedSurveyId] = useState(null);

  useEffect(() => {
    const fetchSurveys = async () => {
      try {
        const response = await axiosInstance.get("/surveys");
        setSurveys(response.data);
      } catch (err) {
        setError("Error fetching surveys");
      } finally {
        setLoading(false);
      }
    };
    fetchSurveys();
  }, []);

  const handleViewStatistics = (surveyId) => {
    setSelectedSurveyId(surveyId);
  };

  if (loading) {
    return <div style={styles.spinner}>Loading...</div>;
  }

  if (error) {
    return <div style={styles.error}>{error}</div>;
  }

  return (
    <div style={styles.container}>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.header}>Title</th>
            <th style={styles.header}>Created At</th>
            <th style={styles.header}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {surveys.map((survey) => (
            <tr key={survey._id}>
              <td style={styles.cell}>{survey.title}</td>
              <td style={styles.cell}>
                {new Date(survey.createdAt).toLocaleDateString()}
              </td>
              <td style={styles.cell}>
                <button
                  style={styles.viewButton}
                  onClick={() => handleViewStatistics(survey._id)}
                >
                  View Statistics
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedSurveyId && (
        <SurveyStatisticsPopup
          surveyId={selectedSurveyId}
          onClose={() => setSelectedSurveyId(null)}
        />
      )}
    </div>
  );
};

const styles = {
  container: {
    marginTop: "2rem",
    padding: "20px",
    borderRadius: "8px",
    border: "1px solid #ccc",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "1rem",
  },
  header: {
    backgroundColor: "#222",
    color: "#fff",
    padding: "12px 16px",
    textAlign: "left",
    fontWeight: "bold",
  },
  cell: {
    padding: "12px 16px",
    textAlign: "left",
    borderBottom: "1px solid #ddd",
  },
  viewButton: {
    padding: "8px 16px",
    backgroundColor: "#444",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  spinner: {
    textAlign: "center",
    fontSize: "24px",
    color: "#888",
  },
  error: {
    color: "red",
    textAlign: "center",
    marginTop: "1rem",
  },
};

export default SurveyTable;
