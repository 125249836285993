import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import axiosInstance from "../services/axiosInstance";

// Component for the popup
const TextSubmitPopup = ({ showModal, closeModal }) => {
  const [text, setText] = useState("");
  const toast = useToast();

  const handleSubmit = async () => {
    try {
      await axiosInstance.post("/users/sendmail", { text });
      toast({
        title: "Success",
        description: "Text sent to the users successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setText(""); // Clear the input field
      closeModal(); // Close the modal
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to send text. Try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={showModal} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Send Text to users</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder="Enter text here"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Send
          </Button>
          <Button variant="ghost" onClick={closeModal}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default TextSubmitPopup;