import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import axiosInstance from "../services/axiosInstance";

const isArabic = (text) => /[\u0600-\u06FF]/.test(text);

const SurveyStatisticsPopup = ({ surveyId, onClose }) => {
  const { isOpen, onOpen, onClose: closeModal } = useDisclosure();
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (surveyId) {
      onOpen();
      fetchStatistics(surveyId);
    }
  }, [surveyId]);

  const fetchStatistics = async (surveyId) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/surveys/${surveyId}/statistics`);
      setStatistics(response.data.statistics);
    } catch (error) {
      console.error("Error fetching statistics", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    closeModal();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Survey Statistics</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading ? (
            <Box textAlign="center" py={4}>
              <Spinner size="xl" />
              <Text mt={2}>Loading statistics...</Text>
            </Box>
          ) : statistics ? (
            statistics.map((stat, index) => {
              const isRTL = isArabic(stat.questionText);
              return (
                <Box
                  key={index}
                  mb={6}
                  dir={isRTL ? "rtl" : "ltr"}
                  textAlign={isRTL ? "right" : "left"}
                >
                  <Text fontSize="lg" fontWeight="bold" mb={4}>
                    {stat.questionText}
                  </Text>
                  <Table variant="striped" colorScheme="blue" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Answer</Th>
                        <Th>Responses</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {Object.entries(stat.responseDetails).map(
                        ([answer, count]) => (
                          <Tr key={answer}>
                            <Td>{answer}</Td>
                            <Td>{count}</Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </Box>
              );
            })
          ) : (
            <Text>No statistics available for this survey.</Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SurveyStatisticsPopup;
