import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Stack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { formatDistanceToNow } from 'date-fns';
import { FaVideo, FaEye, FaRegClock, FaCalendarAlt, FaUser, FaEnvelope, FaSearch } from 'react-icons/fa';
import axiosInstance from '../services/axiosInstance';
const getTimeAgo = (isoDateString) => {
  const date = new Date(isoDateString);

  // Using toLocaleString to format the date in a human-readable format
  const options = {
    weekday: 'long', // Example: 'Monday'
    year: 'numeric',
    month: 'long', // Example: 'December'
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true, // For 12-hour format
  };

  return date.toLocaleString('fr-FR', options);
};
const formatTime = (seconds) => {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${hrs}h ${mins}m ${secs}s`;
};

const calculateTotalDuration = (videos) => {
  return videos.reduce((total, video) => total + video.duration, 0);
};

const UserStatisticsModal = ({ isOpen, onClose, userdata }) => {
  function formatDuration(timestamp) {
    const date = new Date(timestamp);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
  
    const days = Math.floor(diffInSeconds / (24 * 3600));
    const hours = Math.floor((diffInSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;
  
    let formattedDuration = '';
    
    if (days > 0) formattedDuration += `${days} jour${days > 1 ? 's' : ''}`;
    if (hours > 0) formattedDuration += ` et ${hours} heure${hours > 1 ? 's' : ''}`;
    if (minutes > 0) formattedDuration += ` et ${minutes} min${minutes > 1 ? 's' : ''}`;
    if (seconds > 0) formattedDuration += ` et ${seconds} s`;
  
    return formattedDuration;
  }
  
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredVideos, setFilteredVideos] = useState([]);

  useEffect(() => {
    if (isOpen && userdata) {
      setLoading(true);
      axiosInstance
        .get(`/session/user/${userdata._id}`)
        .then((response) => {
          setSessions(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching session data:', error);
          setLoading(false);
        });
    }
  }, [isOpen, userdata]);

  const allVideos = sessions.flatMap((session) => session.videos);
  const totalDuration = calculateTotalDuration(allVideos);

  useEffect(() => {
    setFilteredVideos(
      allVideos.filter((video) =>
        video.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, allVideos]);

  const defaultVideoImage = 'https://i.fbcd.co/products/resized/resized-750-500/e2d58bd824dd0b3b9786845286c4f998bb01f7b012ec47d2bd6e99e7bac56405.jpg';

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="blue.600">Statistiques des Vidéos</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={6}>
            <Box>
              <Text fontSize="lg" fontWeight="bold">
                <Icon as={FaUser} color="yellow.400" mr={2} />
                Utilisateur : {userdata?.username || 'Non spécifié'}
              </Text>
              <Text>
                <Icon as={FaEnvelope} color="gray.500" mr={2} />
                Email : {userdata?.email || 'Non spécifié'}
              </Text>
            </Box>

            <Box>
              <InputGroup maxWidth="400px">
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaSearch} color="gray.500" />
                </InputLeftElement>
                <Input
                  placeholder="Rechercher une vidéo..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  size="md"
                  borderRadius="lg"
                  focusBorderColor="teal.400"
                />
              </InputGroup>
            </Box>

            {loading ? (
              <Text color="gray.500">Chargement des données...</Text>
            ) : (
              <Box>
                <Text fontSize="md" fontWeight="bold" mb={2}>
                  <Icon as={FaCalendarAlt} color="teal.500" mr={2} />
                  Détails des vidéos :
                </Text>

                <Box mb={4}>
                  <Text fontSize="sm" color="blue.500">
                    <Icon as={FaRegClock} mr={2} />
                    Durée Totale : {formatTime(totalDuration)}
                  </Text>
                  <Text fontSize="sm" color="blue.500">
                    <Icon as={FaEye} mr={2} />
                    Nombre d'ouvertures : {userdata.tokenVersion || 0} fois
                  </Text>
                </Box>

                <Stack
                  spacing={4}
                  maxHeight="500px"
                  overflowY={filteredVideos.length > 6 ? 'scroll' : 'auto'}
                  pr={2}
                >
                  {filteredVideos.length === 0 ? (
                    <Text color="gray.600" textAlign="center">
                      Aucune vidéo ne correspond à votre recherche.
                    </Text>
                  ) : (
                    filteredVideos.map((video) => (
                      <Box
                        key={video.title}
                        p={4}
                        borderWidth="1px"
                        borderRadius="lg"
                        boxShadow="sm"
                        borderColor="teal.200"
                        _hover={{ bg: 'teal.50' }}
                        transition="background-color 0.2s ease-in-out"
                      >
                        <Stack direction="row" spacing={4} align="center">
                          <Image
                            boxSize="60px"
                            objectFit="cover"
                            borderRadius="md"
                            src={video.thumbnail || defaultVideoImage}
                            alt={video.title}
                          />
                          <Box>
                            <Text fontWeight="bold" color="teal.600" fontSize="md">
                              {video.title}
                            </Text>
                            <Text color="gray.600" fontSize="sm">
                              <Icon as={FaRegClock} color="gray.500" mr={2} />
                              Temps de visionnage : {formatTime(video.duration)}
                            </Text>
                            <Text color="gray.500">
                              Dernière visionnage :{' '}
                            
                              {video.date
                                ? (() => {
                                  try {
                                    console.log(video.date)
                                    return getTimeAgo(video.date);
                                  } catch (error) {
                                    console.error('Error formatting date:', video.date, error);
                                    return 'Date invalide';
                                  }
                                })()
                                : 'Date inconnue'}
                            </Text>
                          </Box>
                        </Stack>
                      </Box>
                    ))
                  )}
                </Stack>
              </Box>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose} borderRadius="lg">
            Fermer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserStatisticsModal;
