import { useEffect } from 'react';
import axiosInstance from '../services/axiosInstance';

const useTrackSession = (videoId) => {
  useEffect(() => {
    if (!videoId || !videoId._id || !videoId.nomvideo) {
      console.error('Invalid videoId:', videoId);
      return;
    }

    const startTime = new Date();
    let intervalId;

    // Function to log session data using axios
    const logSession = async (startTime, endTime, sessionDuration) => {
      console.log("Session Duration:", sessionDuration, "seconds");


      const userToken = localStorage.getItem('userToken');  // Or get the token from your auth state

      try {

        // Use axiosInstance to send data
        const response = await axiosInstance.post('/session/logSession', {
          videoId: videoId._id,
          duration: sessionDuration,
          videoTitle:videoId.nomvideo
        }, {
          headers: {
            'Authorization': `Bearer ${userToken}`,  // Include token for authentication
          },
        });

        console.log('Session logged:', response.data);
      } catch (error) {
        console.error('Error logging session:', error);
      }
    };

    // Start interval to update every 30 seconds
    intervalId = setInterval(() => {
      const currentTime = new Date();
      const sessionDuration = Math.round((currentTime - startTime) / 1000);
      logSession(startTime, currentTime, sessionDuration);
    }, 30000); // 30 seconds

    // Cleanup logic: Log final session duration and clear interval
    const handleBeforeUnload = () => {
      const endTime = new Date();
      const sessionDuration = Math.round((endTime - startTime) / 1000);
      logSession(startTime, endTime, sessionDuration);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('beforeunload', handleBeforeUnload);

      // Log final session duration on component unmount
      const endTime = new Date();
      const sessionDuration = Math.round((endTime - startTime) / 1000);
      logSession(startTime, endTime, sessionDuration);
    };
  }, [videoId]);

};

export default useTrackSession;
