import axiosInstance from './axiosInstance'; // Import your axios instance

const userService = {
  // Fetch all users
  getUsers: async () => {
    try {
      const response = await axiosInstance.get('/users');
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Add a new user
  addUser: async (userData) => {
    try {
      console.log(userData)
      const response = await axiosInstance.post('/auth/register', userData);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error('API Error:', error.response.data);
        throw new Error(error.response.data.message || 'Failed to add user.');
      } else {
        console.error('Unexpected Error:', error.message);
        throw new Error('An unexpected error occurred while adding the user.');
      }
    }
  },
  
  updateUser: async (userId, updatedData) => {
    try {
      const response = await axiosInstance.put(`/users/${userId}`, updatedData);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error('API Error:', error.response.data);
        throw new Error(error.response.data.message || 'Failed to update user.');
      } else {
        console.error('Unexpected Error:', error.message);
        throw new Error('An unexpected error occurred while updating the user.');
      }
    }
  },

  // Delete a user
  deleteUser: async (userId) => {
    try {
      const response = await axiosInstance.delete(`/users/user/${userId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default userService;
