import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { fetchCourses } from '../services/courseService';
import { FaPlus, FaTrash,FaEdit   } from 'react-icons/fa';
import { MdInsertChart } from 'react-icons/md';
import Sidebar2 from '../shared/Sidebar2';
import { useTable } from 'react-table';
import userService from '../services/userService';
import RegisterUsersButton, { registerUsers } from './js';

import NewPopupModal from './../shared/statistique_user';
import EditUserModal from '../shared/EditUserModel';
import TextSubmitPopup from '../shared/sendEmail';


const UserAdminManagement = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const [users, setUsers] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [StatUser, setStatUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [matiersOptions, setmatiersOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 10;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
 

  const toast = useToast();

  useEffect(() => {
    fetchUsers();
    fetchcoursesNames()

  }, []);

  useEffect(() => {
    filterUsers();
  }, [searchTerm, users]);
const fetchcoursesNames=async()=>{
  try{
    let data=await fetchCourses()
    data=data.map(el=>({label:el.name,value:el.name}))
    setmatiersOptions(data)
  }
  catch(error){

  }
}

const handleCloseModal = () => setModalOpen(false);
  const fetchUsers = async () => {
    try {
      const fetchedUsers = await userService.getUsers();
      setUsers(fetchedUsers);
    } catch (error) {
      console.error('Échec de la récupération des utilisateurs:', error);
      toast({
        title: 'Erreur',
        description: 'Échec de la récupération des utilisateurs.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const filterUsers = () => {
    if (!searchTerm) {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(
        users.filter(user =>
          (user.username && user.username.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()))
        )
      );
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateFields = () => {
    if (!selectedUser.name || !selectedUser.email) {
      setErrorMessage('Tous les champs doivent être remplis.');
      return false;
    }
    if (!validateEmail(selectedUser.email)) {
      setErrorMessage("L'adresse email est invalide.");
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const handleAddUser = (user) => {
    if(user){

      
      setSelectedUser({...user,name:user.username});
    }
    else{
   
      setSelectedUser({ name: '', email: '' });
    }
    onOpen();
  };

 

  const handleDeleteUser = (user) => {
    setDeletingUser(user._id);
    onOpen();
  };
  const handleStatUser = (user) => {
    setStatUser(user);
    setModalOpen(true)
  };
  const confirmDeleteUser = async () => {
    try {
      await userService.deleteUser(deletingUser);
      await fetchUsers();
      toast({
        title: 'Succès',
        description: 'Utilisateur supprimé avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Échec de la suppression de l’utilisateur:", error);
      toast({
        title: 'Erreur',
        description: "Échec de la suppression de l’utilisateur.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setDeletingUser(null);
    onClose();
  };
  const handleSaveUser = async () => {
    try {
      if (!selectedUser.name || !selectedUser.email) {
        setErrorMessage('Le nom et l\'email sont obligatoires.');
        return;
      }
  
      setErrorMessage(''); // Clear any previous errors
  
      if (selectedUser._id) {
        // Edit an existing user
        await userService.updateUser(selectedUser._id, selectedUser);
        toast({
          title: 'Utilisateur modifié.',
          description: 'L\'utilisateur a été modifié avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Add a new user
        await userService.addUser({
          email: selectedUser.email,
          password: generatePassword(),
          username: selectedUser.name,
          matiers:selectedUser.matiers
        });
        toast({
          title: 'Utilisateur ajouté.',
          description: 'L\'utilisateur a été ajouté avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
  
      onClose(); // Close the modal after saving
    } catch (error) {
      console.error('Error:', error.message);
      setErrorMessage(error.message || 'Une erreur est survenue, veuillez réessayer.');
    } finally {
      fetchUsers(); // Refresh the user list
    }
  };
  

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const paginatedUsers = filteredUsers.slice(
    currentPage * usersPerPage,
    (currentPage + 1) * usersPerPage
  );

  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nom',
        accessor: 'username',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
   
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <Flex gap={2}>
            <IconButton
              icon={<FaEdit />}
              aria-label="Modifier l'utilisateur"
              size="sm"
              colorScheme="yellow"
              onClick={() => handleAddUser(row.original)} 
            />
            <IconButton
              icon={<FaTrash />}
              aria-label="Supprimer l'utilisateur"
              size="sm"
              colorScheme="red"
              onClick={() => handleDeleteUser(row.original)}
            />
            <IconButton
              icon={<MdInsertChart />}
              aria-label="Statistique de l'utilisateur"
              size="sm"
              colorScheme="blue"
              onClick={() => handleStatUser(row.original)}
            />
          </Flex>
        ),
      },
    ],
    [filteredUsers]
  );
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: paginatedUsers });

  const generatePassword = (length = 8) => {
    const charset = "0123456789";
    let password = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    return password;
  };
  const openEditModal = (user) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedUser(null);
    setIsEditModalOpen(false);
  };

  const updateUserList = (updatedUser) => {
    console.log("Utilisateur mis à jour :", updatedUser);
    // Mettre à jour la liste des utilisateurs ici si nécessaire
  };
  return (
    <Flex h="100vh" bg="#F7FAFC">
      <Sidebar2 />

      <Box flex="1" p={6} ml={{ base: '60px', lg: '250px' }}>
        <Heading mb={8} color="#032D7F">
          Gestion des Utilisateurs
        </Heading>
        <Flex mb={4} justify="space-between">
        <Button colorScheme="blue" onClick={openModal}>
        Envoyer Email
      </Button>
        <Input
            placeholder="Rechercher un utilisateur..."
            maxW="300px"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button leftIcon={<FaPlus />} colorScheme="blue" onClick={handleAddUser}>
            Ajouter Utilisateur
          </Button>
      
        </Flex>
        <Box bg="white" p={6} borderRadius="md" shadow="md">
          <Table {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <Td style={{width:"33%"}} {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Flex mt={4} justify="center">
            {[...Array(totalPages).keys()].map((page) => (
              <Button
                key={page}
                onClick={() => handlePageChange(page)}
                colorScheme={page === currentPage ? 'blue' : 'gray'}
                mx={1}
              >
                {page + 1}
              </Button>
            ))}
          </Flex>
        </Box>

        <Modal isOpen={!!deletingUser} onClose={() => setDeletingUser(null)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmer la suppression</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p>Êtes-vous sûr de vouloir supprimer cet utilisateur ? Cette action est irréversible.</p>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" mr={3} onClick={confirmDeleteUser}>
                Supprimer
              </Button>
              <Button variant="ghost" onClick={() => setDeletingUser(null)}>
                Annuler
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

     

        <Modal isOpen={isOpen && !deletingUser} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{selectedUser ? 'Modifier un Utilisateur' : 'Ajouter un Utilisateur'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Input
              placeholder="Nom"
              value={selectedUser?.name || ''}
              onChange={(e) => setSelectedUser({ ...selectedUser, name: e.target.value })}
            />
            <Input
              placeholder="Email"
              value={selectedUser?.email || ''}
              onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
            />
            <Box>
              <ReactSelect
                isMulti
                options={matiersOptions}
                placeholder="Sélectionner des Matières"
                value={(selectedUser?.matiers || []).map((el) => ({ label: el, value: el }))}
                onChange={(selected) =>
                  setSelectedUser({ ...selectedUser, matiers: selected.map((item) => item.value) })
                }
              />
            </Box>
            {errorMessage && (
              <Box color="red.500" mt={2}>
                {errorMessage}
              </Box>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSaveUser}>
            {selectedUser ? 'Sauvegarder' : 'Ajouter'}
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

      </Box>
      <NewPopupModal isOpen={isModalOpen} onClose={handleCloseModal} userdata={StatUser} />
      <div>
      
      {isModalOpen1 && (
        <TextSubmitPopup showModal={isModalOpen1} closeModal={closeModal} />
      )}
    </div>
    </Flex>
  );
};

export default UserAdminManagement;
